import React, { useEffect, useState } from 'react';
import MaterialTable, {
    MTableBody,
    MTableFilterRow,
    MTableToolbar,
} from 'material-table';
import axios from 'axios';
import { API_URL } from '../../../utils/setApiUrl.js';
import 'date-fns';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import { Settings } from 'luxon';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx/dist/xlsx.full.min.js';
import DescriptionIcon from '@material-ui/icons/Description';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import WebAppConfigService from '../../../services/WebAppConfigService';

Settings.defaultZone = 'Asia/Istanbul';

const SPACED_DATE_FORMAT = 'DD/MMM/YYYY H:MM:ss';

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    grid: {
        marginLeft: theme.spacing(2),
    },
    toolbar: { marginLeft: theme.spacing(10) },
    absoluteContainer: {
        position: 'absolute',
        zIndex: 9,
        width: '45vw',
        [theme.breakpoints.down('md')]: {
            position: 'initial',
        },
    },
}));

const WorkingHoursTable = () => {
    const classes = useStyles();

    useEffect(() => {
        retrieveSystemSettings();
    }, []);

    const [settings, setSettings] = useState([]);

    const retrieveSystemSettings = () => {
        WebAppConfigService.getAll()
            .then((response) => {
                setSettings(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const locData = JSON.parse(localStorage.getItem('loc'));

    const client = axios.create({
        baseURL: API_URL,
    });

    // ! Gap Filter Start

    var x = new Date();
    const [selectedStartDate, setStartDateChange] = useState(null);
    const [selectedEndDate, setEndDateChange] = useState(null);

    const handleEndDateChange = (date) => {
        setEndDateChange(date);
    };

    const handleStartDateChange = (date) => {
        // Format the date to 'YYYY-MM-DD'
        const formattedDate = date.format('YYYY-MM-DD');

        // Update the state with the formatted date
        setStartDateChange(formattedDate);
    };

    const [events, setEvent] = useState([]);

    const [filteredDay, setFilteredDay] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const retrieveFilteredDay = async () => {
        setIsLoading(true); // Start loading

        setFilteredDay([]);

        // Format the start date as a local date string
        const startDate = selectedStartDate;

        let endDate = new Date(selectedEndDate);
        endDate.setDate(endDate.getDate() + 1);

        // Format the end date as a local date string
        const newEndDate = endDate.toISOString().split('T')[0];

        if (Array.isArray(locData)) {
            const joinedValues = locData.join(',');
            const request = client.get(
                `/working-hours/date/${startDate}/${newEndDate}/loc/${joinedValues}`
            );
            try {
                const response = await request;
                if (response && response.data) {
                    // Ensure response has the data you need
                    setFilteredDay(response.data); // Assuming the relevant data is directly in response.data
                    setDataFetched(true);
                } else {
                    throw new Error('No data found in response');
                }
                setOpen(false);
            } catch (error) {
                setIserror(error.response);
                setErrorMessages('Не бяха намарени отклонения!'); // You can handle the JSX part where you render this message
                console.error(error.response);
            } finally {
                setIsLoading(false); // End loading regardless of success or failure
            }
        } else if (locData.id === 'all') {
            const request = client.get(
                `/working-hours/date/${startDate}/${newEndDate}/all`
            );
            try {
                const response = await request;
                if (response && response.data) {
                    // Ensure response has the data you need
                    setFilteredDay(response.data); // Assuming the relevant data is directly in response.data
                    setDataFetched(true);
                } else {
                    throw new Error('No data found in response');
                }
                setOpen(false);
            } catch (error) {
                setIserror(error.response);
                setErrorMessages('Не бяха намарени отклонения!'); // You can handle the JSX part where you render this message
                console.error(error.response);
            } finally {
                setIsLoading(false); // End loading regardless of success or failure
            }
        }
    };

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    // * Add Filters to State Start
    const [selectedRows, setSelectedRows] = useState([]);

    // const [components] = useState({
    // 	Toolbar = props => <MTableToolbar {...props} />,
    // })

    // * Add Filters to State End

    const [components] = useState({
        Toolbar: (props) => (
            <div>
                <MTableToolbar {...props} />
            </div>
        ),
        FilterRow: (props) => <MTableFilterRow {...props} />,
    });

    // ! Define table columns for export
    const [columns] = useState([
        {
            title: '# на Служител',
            field: 'user_id',
            editable: 'never',
        },
        { title: 'Име', field: 'user_name', editable: 'never' },
        {
            title: 'Локация',
            field: 'loc_name',
            editable: 'never',
        },
        {
            title: 'Отдел',
            field: 'sub_loc_name',
            editable: 'never',
        },
        {
            title: 'Дата',
            field: 'date',
            type: 'date',
            dateSetting: { locale: 'bg-BG', hour12: false },
            editable: 'never',
        },
        {
            title: 'Първи Вход',
            field: 'first_entry_time',
            filtering: false,
            editable: 'never',
            type: 'datetime',
            dateSetting: { locale: 'bg-BG', hour12: false },
        },
        {
            title: 'Последен Изход',
            filtering: false,
            field: 'last_exit_time',
            editable: 'never',
            type: 'datetime',
            dateSetting: { locale: 'bg-BG', hour12: false },
        },
        {
            title: 'Дневна смяна',
            field: 'day_shift',
            cellStyle: { whiteSpace: 'nowrap' },
        },
        // {
        //     title: 'Изработено време - дневна (часове)',
        //     field: 'day_shift_hours',
        //     cellStyle: { whiteSpace: 'nowrap' },
        // },
        {
            title: 'Нощна смяна',
            field: 'night_shift',
            cellStyle: { whiteSpace: 'nowrap' },
        },
        // {
        //     title: 'Изработено време - нощна (часове)',
        //     field: 'night_shift_hours',
        //     cellStyle: { whiteSpace: 'nowrap' },
        // },
        {
            title: 'Почивка (фиксирана)',
            field: 'rest_period',
            cellStyle: { whiteSpace: 'nowrap' },
        },
        {
            title: 'Общо часове',
            field: 'total_worked_time_minus_rest',
            cellStyle: { whiteSpace: 'nowrap' },
        },
        {
            title: 'Проблем',
            field: 'problem',
            cellStyle: { whiteSpace: 'nowrap' },
        },
    ]);

    // ! Format the Date and time columns for export
    const formatDate = (inputTime) => {
        if (inputTime && inputTime !== 'null' && inputTime !== 'empty') {
            const date = new Date(inputTime);
            return `${date.getDate().toString().padStart(2, '0')}.${(
                date.getMonth() + 1
            )
                .toString()
                .padStart(2, '0')}.${date.getFullYear()} г., ${date
                .getHours()
                .toString()
                .padStart(2, '0')}:${date
                .getMinutes()
                .toString()
                .padStart(2, '0')}:${date
                .getSeconds()
                .toString()
                .padStart(2, '0')} ч.`;
        }
        return 'empty';
    };

    // ! Header mapping for exported column titles
    const headerMapping = {};
    columns.forEach((col) => {
        headerMapping[col.field] = col.title;
    });

    const mapHeaders = (row) => {
        let newRow = {};
        for (let key in row) {
            if (headerMapping[key]) {
                newRow[headerMapping[key]] = row[key];
            } else {
                newRow[key] = row[key];
            }
        }
        return newRow;
    };

    // ! Format the name for the file
    const formatDateForFilename = (inputDate) => {
        const date = new Date(inputDate);
        return `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    };

    const formattedStartDate = formatDateForFilename(selectedStartDate);
    const formattedEndDate = formatDateForFilename(selectedEndDate);

    // ! Export to XLS Function
    const tableRef = React.createRef();
    const exportTable = () => {
        // Get the filtered data from the table
        const currentTableData = tableRef.current.dataManager.filteredData;

        // Transform the data
        const transformedData = currentTableData.map((row) => {
            const hours = row.worked_hours || 0; // Use 0 if worked_hours is null or undefined
            const mins = row.mins || 0; // Use 0 if mins is null or undefined

            // Check for << and >> conditions for first_time and last_time
            const firstTimeValue =
                row.first_time === '<<'
                    ? 'липсва вход'
                    : formatDate(row.first_time);
            const lastTimeValue =
                row.last_time === '>>'
                    ? 'липсва изход'
                    : formatDate(row.last_time);

            return {
                ...row,
                date: formatDate(row.date),
                time: formatDate(row.time),
                first_time: firstTimeValue,
                last_time: lastTimeValue,
                worked_hours: `${hours} часа и ${mins} минути`,
            };
        });

        // Only include displayed columns in the export
        const exportedData = transformedData.map((row) => {
            let newRow = {};
            columns.forEach((col) => {
                newRow[col.field] = row[col.field];
            });
            return mapHeaders(newRow);
        });

        // Export the data
        const ws = XLSX.utils.json_to_sheet(exportedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const buf = XLSX.write(wb, { bookType: 'xls', type: 'array' });
        saveAs(
            new Blob([buf], { type: 'application/vnd.ms-excel' }),
            `Working_Hours_${formattedStartDate} - ${formattedEndDate}.xls`
        );
    };

    const exportTableCsv = () => {
        // Get the filtered data from the table
        const currentTableData = tableRef.current.dataManager.filteredData;

        // Transform the data
        const transformedData = currentTableData.map((row) => {
            const hours = row.worked_hours || 0; // Use 0 if worked_hours is null or undefined
            const mins = row.mins || 0; // Use 0 if mins is null or undefined

            // Check for << and >> conditions for first_time and last_time
            const firstTimeValue =
                row.first_entry_time === '<<'
                    ? 'липсва вход'
                    : formatDate(row.first_entry_time);
            const lastTimeValue =
                row.last_exit_time === '>>'
                    ? 'липсва изход'
                    : formatDate(row.last_exit_time);

            return {
                ...row,
                date: formatDate(row.date),
                time: formatDate(row.time),
                first_entry_time: formatDate(row.first_entry_time),
                last_exit_time: formatDate(row.last_exit_time),
                worked_hours: `${hours} часа и ${mins} минути`,
            };
        });

        // Only include displayed columns in the export
        const exportedData = transformedData.map((row) => {
            const newRow = {};
            columns.forEach((col) => {
                newRow[col.field] = row[col.field];
            });
            return mapHeaders(newRow);
        });

        // Export the data
        const ws = XLSX.utils.json_to_sheet(exportedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const buf = XLSX.write(wb, { bookType: 'csv', type: 'array' });
        saveAs(
            new Blob([buf], { type: 'application/vnd.ms-excel' }),
            `Working_Hours_${formattedStartDate} - ${formattedEndDate}.csv`
        );
    };

    return (
        <div>
            <Paper>
                <Grid
                    container
                    spacing={1}
                    className={classes.absoluteContainer}
                    style={{ paddingLeft: '16px' }}
                >
                    <Grid item xs={4} lg={3} style={{ paddingTop: '20px' }}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="bg"
                        >
                            <MobileDatePicker
                                margin="normal"
                                closeOnSelect={true}
                                id="selectedStartDate"
                                label="Начална Дата"
                                defaultValue={dayjs()}
                                ampm={false}
                                value={selectedStartDate}
                                onChange={(date) => handleStartDateChange(date)}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4} lg={3} style={{ paddingTop: '20px' }}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="bg"
                        >
                            <MobileDatePicker
                                margin="normal"
                                closeOnSelect={true}
                                id="selectedEndDate"
                                label="Крайна Дата"
                                defaultValue={dayjs()}
                                value={selectedEndDate}
                                onChange={handleEndDateChange}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4} lg={6}>
                        {/*<Button*/}
                        {/*    type="submit"*/}
                        {/*    variant="contained"*/}
                        {/*    color="primary"*/}
                        {/*    className={classes.button}*/}
                        {/*    onClick={retrieveFilteredDay}*/}
                        {/*>*/}
                        {/*    Филтрирай*/}
                        {/*</Button>*/}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={retrieveFilteredDay}
                            disabled={isLoading} // Disable button when loading
                        >
                            {isLoading ? 'Зареждаме...' : 'Филтрирай'}
                        </Button>
                    </Grid>
                </Grid>
                <MaterialTable
                    title=""
                    columns={[
                        {
                            title: '# на Служител',
                            field: 'user_id',
                            editable: 'never',
                        },
                        { title: 'Име', field: 'user_name', editable: 'never' },
                        {
                            title: 'Локация',
                            field: 'loc_name',
                            editable: 'never',
                        },
                        {
                            title: 'Отдел',
                            field: 'sub_loc_name',
                            editable: 'never',
                        },
                        {
                            title: 'Дата',
                            field: 'date',
                            type: 'date',
                            dateSetting: { locale: 'bg-BG', hour12: false },
                            editable: 'never',
                            filtering: false,
                        },
                        {
                            title: 'Първи Вход',
                            field: 'first_entry_time',
                            filtering: false,
                            editable: 'never',
                            type: 'datetime',
                            dateSetting: { locale: 'bg-BG', hour12: false },
                        },
                        {
                            title: 'Последен Изход',
                            filtering: false,
                            field: 'last_exit_time',
                            editable: 'never',
                            type: 'datetime',
                            dateSetting: { locale: 'bg-BG', hour12: false },
                        },
                        {
                            title: 'Дневна смяна',
                            field: 'day_shift',
                            cellStyle: { whiteSpace: 'nowrap' },
                            filtering: false,
                        },
                        // {
                        //     title: 'Изработено време - дневна (часове)',
                        //     field: 'day_shift_hours',
                        //     cellStyle: { whiteSpace: 'nowrap' },
                        // },
                        {
                            title: 'Нощна смяна',
                            field: 'night_shift',
                            cellStyle: { whiteSpace: 'nowrap' },
                            filtering: false,
                        },
                        // {
                        //     title: 'Изработено време - нощна (часове)',
                        //     field: 'night_shift_hours',
                        //     cellStyle: { whiteSpace: 'nowrap' },
                        // },
                        {
                            title: 'Почивка (фиксирана)',
                            field: 'rest_period',
                            cellStyle: { whiteSpace: 'nowrap' },
                            filtering: false,
                        },
                        {
                            title: 'Общо часове',
                            field: 'total_worked_time_minus_rest',
                            cellStyle: { whiteSpace: 'nowrap' },
                            filtering: false,
                        },
                        {
                            title: 'Проблем',
                            field: 'problem',
                            filtering: false,
                            cellStyle: { whiteSpace: 'nowrap' },
                        },
                    ]}
                    data={filteredDay || iserror}
                    tableRef={tableRef}
                    style={{
                        borderRadius: '0',
                        boxShadow: 'none',
                    }}
                    components={{
                        components,
                        Body: (props) =>
                            !dataFetched ? (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        margin: '15% auto',
                                        position: 'absolute',
                                        textAlign: 'center',
                                    }}
                                >
                                    "Изберете период за да видите Справка
                                    Часове!"
                                </div>
                            ) : (
                                <MTableBody {...props} />
                            ),
                    }}
                    actions={[
                        {
                            icon: () => <DownloadOutlinedIcon />,
                            tooltip: 'Export to CSV',
                            isFreeAction: true,
                            onClick: exportTableCsv,
                        },
                        {
                            icon: () => <DescriptionIcon />,
                            tooltip: 'Export to Excel',
                            isFreeAction: true,
                            onClick: exportTable,
                        },
                    ]}
                    options={{
                        loadingType: 'linear',
                        maxBodyHeight: '67vh',
                        minBodyHeight: '67vh',
                        showTitle: true,
                        sorting: true,
                        actionsColumnIndex: -1,
                        filtering: true,
                        pageSizeOptions: [50, 100, 200, 300, 400, 500],
                        search: false,
                        toolbar: true,
                    }}
                    onSelectionChange={(rows) => {
                        setSelectedRows(rows);
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} от {count}',
                            labelRowsSelect: 'Събития',
                        },
                        header: {
                            actions: 'Действия',
                        },
                        body: {
                            emptyDataSourceMessage:
                                'Изберете период за да видите изработени часове и почивки!',
                            editTooltip: 'Редактирай',
                            deleteTooltip: 'Премахни',
                            filterRow: {
                                filterTooltip: 'Филтър',
                            },
                            editRow: {
                                deleteText:
                                    'Сигурни ли сте че искате да изтриете събитието?',
                                cancelTooltip: 'Отказ',
                                saveTooltip: 'Запази',
                            },
                        },
                        toolbar: {
                            exportTitle: 'Експорт',
                            exportName: 'Експорт към CSV',
                            searchTooltip: 'Tърси',
                            searchPlaceholder: 'Tърси',
                            nRowsSelected: '{0} реда избрани',
                        },
                    }}
                />
            </Paper>
        </div>
    );
};

export default WorkingHoursTable;
