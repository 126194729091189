import React, { useState } from 'react';
import DeviceUsersService from '../../../../services/DeviceUsersService';
import MaterialTable, { MTableFilterRow } from 'material-table';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@mui/material';
import { API_URL } from '../../../../utils/setApiUrl';
import axios from 'axios';

function Attendance({
    tabName,
    deviceUsers,
    deviceMac,
    deviceId,
    deviceName,
    retrieveDeviceUsers,
}) {
    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [snackbars, setSnackbars] = useState([]);

    const handleCloseSnackbar = (id) => {
        setSnackbars((prevSnackbars) =>
            prevSnackbars.filter((snackbar) => snackbar.id !== id)
        );
    };

    const handleShowSnackbar = (message, severity) => {
        const id = new Date().getTime(); // Unique ID for each snackbar
        const newSnackbar = {
            id,
            message,
            severity,
            position: snackbars.length * 55,
        };

        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const [isLoading, setIsLoading] = useState(false);
    const [deallocateMessage, setDeallocationMessage] = useState('');

    const handleRemoveUserFromDevice = async (chosenUserSap, resolve) => {
        setIsLoading(true);
        handleShowSnackbar(
            `Потребител - ${chosenUserSap} се премахва от устройство - ${deviceName}!`,
            'info'
        );

        try {
            const client = axios.create({ baseURL: API_URL, timeout: 10000 });
            const device = deviceMac;

            const response = await client.post('/deallocate', {
                user: JSON.stringify(chosenUserSap),
                device,
            });

            const { status } = response;

            if (status === 200) {
                const { status } = await DeviceUsersService.removeFromDevice(
                    chosenUserSap,
                    deviceId
                );

                if (status === 200) {
                    resolve();
                    handleShowSnackbar(
                        `Успешно премахнахте потребител - ${chosenUserSap} от устройство - ${deviceName}!`,
                        'success'
                    );
                    await new Promise((resolve) => {
                        setTimeout(async () => {
                            await retrieveDeviceUsers(); // Call the retrieveDeviceUsers function after a delay of 5 seconds
                            resolve();
                        }, 3000);
                    });
                } else {
                    resolve();
                    handleShowSnackbar(
                        'Потребителя не беше премахнат, моля опитайте отново!',
                        'error'
                    );
                }
            } else {
                resolve();
                handleShowSnackbar(
                    'Потребителя не беше премахнат, моля опитайте отново!',
                    'error'
                );
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else if (error.code === 'ECONNABORTED') {
                resolve();
                handleShowSnackbar(
                    'Потребителя не беше премахнат, моля опитайте отново!',
                    'error'
                );
            } else {
                console.error(error);
            }
        }
    };

    const columns = [
        {
            title: 'Устройство',
            field: 'allocatedDevice.name',
            editable: 'never',
            defaultFilter: tabName || '',
            hidden: true,
            filtering: true,
        },
        {
            title: 'Служител',
            field: 'userInDevice.name',
        },
        {
            title: '# на служител',
            field: 'userInDevice.sap',
        },
    ];

    const components = {
        FilterRow: (props) => <MTableFilterRow {...props} />,
    };

    return (
        <div>
            {snackbars
                .slice()
                .reverse()
                .map((snackbar, index) => (
                    <Snackbar
                        key={snackbar.id}
                        open={true}
                        autoHideDuration={5000}
                        onClose={() => handleCloseSnackbar(snackbar.id)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        style={{ bottom: `${10 + index * 55}px` }}
                    >
                        <Alert
                            elevation={6}
                            variant="filled"
                            severity={snackbar.severity}
                            onClose={() => handleCloseSnackbar(snackbar.id)}
                        >
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                ))}

            <MaterialTable
                columns={columns}
                data={deviceUsers}
                style={{
                    borderRadius: '0',
                    boxShadow: 'none',
                    padding: '0px !important',
                }}
                options={{
                    loadingType: 'linear',
                    pageSize: 50,
                    maxBodyHeight: '67vh',
                    showTitle: false,
                    toolbar: false,
                    sorting: true,
                    search: false,
                    actionsColumnIndex: -1,
                    exportButton: false,
                    filtering: true,
                    pageSizeOptions: [10, 20, 50, 100, 200, 300, 400, 500],
                }}
                components={components}
                onSelectionChange={(rows) => {
                    setSelectedRows(rows);
                }}
                editable={{
                    onRowDelete: (ChosenUser) =>
                        new Promise((resolve) => {
                            handleRemoveUserFromDevice(
                                ChosenUser.userInDevice.sap,
                                resolve
                            );
                        }),
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} от {count}',
                        labelRowsSelect: 'Служители',
                    },
                    header: {
                        actions: 'Действия',
                    },
                    body: {
                        emptyDataSourceMessage:
                            'Няма служители в това устройство...',
                        editTooltip: 'Редактирай',
                        deleteTooltip: 'Премахни',
                        filterRow: {
                            filterTooltip: 'Филтър',
                        },
                        editRow: {
                            deleteText:
                                'Сигурни ли сте че искате да премахнете служителя от устройството?',
                            cancelTooltip: 'Отказ',
                            saveTooltip: 'Запази',
                        },
                    },
                    toolbar: {
                        exportTitle: 'Експорт',
                        exportName: 'Експорт към CSV',
                        searchTooltip: 'Tърси',
                        searchPlaceholder: 'Tърси',
                        nRowsSelected: '{0} реда избрани',
                    },
                }}
            />
        </div>
    );
}

export default Attendance;
