import {
    Card,
    CardActions,
    CircularProgress,
    Container,
    CssBaseline,
    Grid,
    TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import AppBarComponent from '../../layout/AppBar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { useStyles } from '../../styles/useStyles';
import LocationDataService from '../../../services/LocationService';
import { CardContent, CardHeader } from '@mui/material';
import 'react-month-picker/css/month-picker.css';
import { API_URL } from '../../../utils/setApiUrl.js';
import useCheckPermission from '../../../utils/permissions/useCheckPermissions';

export default function Form76(props) {
    const isSuper = useCheckPermission('super');

    const locData = JSON.parse(localStorage.getItem('loc'));

    const classes = useStyles();

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        retrieveLocations();
    }, []);

    const retrieveLocations = () => {
        LocationDataService.getAll()
            .then((response) => {
                setLocations(response.data);
                console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const [locationValue, setLocationValue] = React.useState('');
    const [locationInputValue, setLocationInputValue] = React.useState('');

    // Month Picker State
    const months = [
        { label: 'Януари', value: 1 },
        { label: 'Февруари', value: 2 },
        { label: 'Март', value: 3 },
        { label: 'Април', value: 4 },
        { label: 'Май', value: 5 },
        { label: 'Юни', value: 6 },
        { label: 'Юли', value: 7 },
        { label: 'Август', value: 8 },
        { label: 'Септември', value: 9 },
        { label: 'Октомври', value: 10 },
        { label: 'Ноември', value: 11 },
        { label: 'Декември', value: 12 },
    ];

    // Get the current month and year
    const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-11, so add 1
    const currentYear = new Date().getFullYear();

    // Set the default selected month and year
    const [selectedMonth, setSelectedMonth] = useState(
        months.find((month) => month.value === currentMonth)
    );
    const [selectedYear, setSelectedYear] = useState({
        label: currentYear.toString(),
        value: currentYear,
    });

    // Month Picker State
    const years = [
        { label: '2023', value: 2023 },
        { label: '2024', value: 2024 },
        { label: '2025', value: 2025 },
        { label: '2026', value: 2026 },
        { label: '2027', value: 2027 },
        { label: '2028', value: 2028 },
        { label: '2029', value: 2029 },
        { label: '2030', value: 2030 },
        { label: '2031', value: 2031 },
        { label: '2032', value: 2032 },
        { label: '2033', value: 2033 },
        { label: '2034', value: 2034 },
    ];

    const [isGenerating, setIsGenerating] = useState(false);

    const handleMonthChange = (event, value) => {
        setSelectedMonth(value);
    };

    const handleYearChange = (event, value) => {
        setSelectedYear(value);
    };

    const handleGenerateClick = () => {
        if (selectedMonth) {
            generateForm76(selectedMonth.value);
        }
    };

    const generateForm76 = async () => {
        setIsGenerating(true); // Start loading/generating

        try {
            const currentYear = new Date().getFullYear();
            const formattedMonth = String(selectedMonth.value).padStart(2, '0');

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                const response = await fetch(
                    `${API_URL}/form76/loc/${joinedValues}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            month: formattedMonth,
                            year: selectedYear,
                        }),
                    }
                );

                if (response.ok) {
                    const blob = await response.blob();

                    // Create a temporary download link
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(blob);
                    downloadLink.download = `Form76_${selectedMonth.value}.xlsx`;

                    // Programmatically trigger the download
                    downloadLink.click();

                    console.log(
                        'Report generation and file download were successful!'
                    );
                } else {
                    throw new Error('Report generation failed');
                }
            } else if (locData.id === 'all') {
                const joinedValues = locData.join(',');
                const response = await fetch(`${API_URL}/form76/all`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        month: formattedMonth,
                        year: selectedYear,
                    }),
                });

                if (response.ok) {
                    const blob = await response.blob();

                    // Create a temporary download link
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(blob);
                    downloadLink.download = `Form76_${selectedMonth.value}.xlsx`;

                    // Programmatically trigger the download
                    downloadLink.click();

                    console.log(
                        'Report generation and file download were successful!'
                    );
                } else {
                    throw new Error('Report generation failed');
                }
            }
        } catch (error) {
            console.error('An error occurred:', error.message);
        } finally {
            setIsGenerating(false); // Stop loading/generating after download starts or fails
        }
    };

    const handleGenerateFullClick = () => {
        if (selectedMonth) {
            generateForm76Full(selectedMonth.value);
        }
    };

    const generateForm76Full = async () => {
        setIsGenerating(true); // Start loading/generating

        try {
            const currentYear = new Date().getFullYear();
            const formattedMonth = String(selectedMonth.value).padStart(2, '0');

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                const response = await fetch(
                    `${API_URL}/form76Full/loc/${joinedValues}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            month: formattedMonth,
                            year: selectedYear.value,
                        }),
                    }
                );

                if (response.ok) {
                    const blob = await response.blob();

                    // Create a temporary download link
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(blob);
                    downloadLink.download = `Form76_${selectedMonth.value}.xlsx`;

                    // Programmatically trigger the download
                    downloadLink.click();

                    console.log(
                        'Report generation and file download were successful!'
                    );
                } else {
                    throw new Error('Report generation failed');
                }
            } else if (locData.id === 'all') {
                const response = await fetch(`${API_URL}/form76Full/all`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        month: formattedMonth,
                        year: selectedYear.value,
                    }),
                });

                if (response.ok) {
                    const blob = await response.blob();

                    // Create a temporary download link
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(blob);
                    downloadLink.download = `Form76_${selectedMonth.value}.xlsx`;

                    // Programmatically trigger the download
                    downloadLink.click();

                    console.log(
                        'Report generation and file download were successful!'
                    );
                } else {
                    throw new Error('Report generation failed');
                }
            }
        } catch (error) {
            console.error('An error occurred:', error.message);
        } finally {
            setIsGenerating(false); // Stop loading/generating after download starts or fails
        }
    };

    const currentHost = window.location.hostname;
    const currentPort = window.location.port;

    // Check if the current host and port match the criteria
    const shouldRender = currentHost === 'billa.biodit.bg' || 'localhost';

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarComponent />
            <main className={classes.content}>
                <Scrollbar
                    id="RSC-Example"
                    style={{ width: '100%', height: '100%' }}
                >
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Grid container spacing={3}>
                            {/*<Grid item xs={4}>*/}
                            {/*    <Card*/}
                            {/*        className={clsx(classes.card)}*/}
                            {/*        elevation={1}*/}
                            {/*    >*/}
                            {/*        <CardHeader*/}
                            {/*            title="Форма 76"*/}
                            {/*            subheader="без специални събития"*/}
                            {/*            sx={{ pb: 0 }}*/}
                            {/*        />*/}
                            {/*        <CardContent sx={{ pt: 0 }}>*/}
                            {/*            <Autocomplete*/}
                            {/*                options={months}*/}
                            {/*                getOptionLabel={(option) =>*/}
                            {/*                    option.label*/}
                            {/*                }*/}
                            {/*                value={selectedMonth}*/}
                            {/*                onChange={handleMonthChange}*/}
                            {/*                renderInput={(params) => (*/}
                            {/*                    <TextField*/}
                            {/*                        {...params}*/}
                            {/*                        label="Изберете Месец"*/}
                            {/*                    />*/}
                            {/*                )}*/}
                            {/*            />*/}

                            {/*<Autocomplete*/}
                            {/*    value={locations.number}*/}
                            {/*    onChange={(*/}
                            {/*        event,*/}
                            {/*        newLocationValue*/}
                            {/*    ) => {*/}
                            {/*        setLocationValue(*/}
                            {/*            newLocationValue*/}
                            {/*        );*/}
                            {/*    }}*/}
                            {/*    inputValue={locationInputValue}*/}
                            {/*    onInputChange={(*/}
                            {/*        event,*/}
                            {/*        locationInputValue*/}
                            {/*    ) => {*/}
                            {/*        setLocationInputValue(*/}
                            {/*            locationInputValue*/}
                            {/*        );*/}
                            {/*    }}*/}
                            {/*    id="auto-select location"*/}
                            {/*    getOptionLabel={(locations) =>*/}
                            {/*        locations.name*/}
                            {/*    }*/}
                            {/*    options={locations}*/}
                            {/*    renderInput={(params) => (*/}
                            {/*        <TextField*/}
                            {/*            {...params}*/}
                            {/*            label="Изберете локация"*/}
                            {/*            variant="outlined"*/}
                            {/*            size="small"*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/*/>*/}
                            {/*        </CardContent>*/}
                            {/*        <CardActions>*/}
                            {/*            <Button*/}
                            {/*                type="submit"*/}
                            {/*                variant="contained"*/}
                            {/*                color="primary"*/}
                            {/*                className={classes.button}*/}
                            {/*                onClick={handleGenerateClick}*/}
                            {/*                disabled={isGenerating} // Disable the button when generating*/}
                            {/*            >*/}
                            {/*                {isGenerating ? (*/}
                            {/*                    <CircularProgress size={24} />*/}
                            {/*                ) : (*/}
                            {/*                    'Генерирай'*/}
                            {/*                )}*/}
                            {/*            </Button>*/}
                            {/*        </CardActions>*/}
                            {/*    </Card>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12} lg={3}>
                                {/*<Form76Table />*/}
                                {shouldRender && (
                                    <Card
                                        className={clsx(classes.card)}
                                        elevation={1}
                                    >
                                        <CardHeader
                                            title="Форма 76"
                                            subheader="със специални събития"
                                            sx={{ pb: 0 }}
                                        />
                                        <CardContent sx={{ pt: 0 }}>
                                            <Autocomplete
                                                options={months}
                                                getOptionLabel={(option) =>
                                                    option.label
                                                }
                                                value={selectedMonth}
                                                onChange={handleMonthChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Изберете Месец"
                                                    />
                                                )}
                                            />
                                            <Autocomplete
                                                options={years}
                                                getOptionLabel={(option) =>
                                                    option.label
                                                }
                                                value={selectedYear}
                                                onChange={handleYearChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Изберете Година"
                                                    />
                                                )}
                                            />
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={
                                                    handleGenerateFullClick
                                                }
                                                disabled={isGenerating} // Disable the button when generating
                                            >
                                                {isGenerating ? (
                                                    <CircularProgress
                                                        size={24}
                                                    />
                                                ) : (
                                                    'Генерирай'
                                                )}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                </Scrollbar>
            </main>
        </div>
    );
}

// export default connect(
//     mapStateToProps,
//     { logoutUser }
// )(Dashboard)
