const getAPIUrl = () => {
    const { protocol, hostname, port } = window.location;

    // Special case: if opened from https://localhost:4000, use https://localhost:5000
    if (hostname === 'localhost' && port === '4000') {
        return 'https://localhost:5000';
    }

    // Default case: construct the API URL dynamically
    const baseUrl = `${protocol}//${hostname}`;
    return `${baseUrl}/api`;
};

export const API_URL = getAPIUrl();
