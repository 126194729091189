import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import setLocToken from '../utils/setLocToken';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { API_URL } from '../utils/setApiUrl.js';
import {
    CLEAR_ERRORS,
    GET_ERRORS,
    SET_CURRENT_USER,
    USER_LOADING,
} from './types';

export const registerUser = (userData, history) => async (dispatch) => {
    try {
        await axios.post(`${API_URL}/auth/register`, userData);
        history.push('/login');
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    }
};

export const loginUser = (userData) => (dispatch) => {
    axios
        .post([API_URL] + '/auth/login', userData)
        .then((res) => {
            // Set token to Cookie
            const { token } = res.data;
            const { loc_token } = res.data;
            const { id_token } = res.data;
            const { level } = res.data;
            const { permissionsToken } = res.data;
            Cookies.set('Biodit', token);
            Cookies.set('loc', loc_token);
            localStorage.setItem('loc', loc_token);
            localStorage.setItem('session', id_token);
            localStorage.setItem('level', level);
            localStorage.setItem('access', permissionsToken);
            // Set token to Auth header
            setAuthToken(token);
            setLocToken(loc_token);
            // Decode token to get user data
            const decoded = jwt_decode(token);
            // Set current user
            dispatch(setCurrentUser(decoded));
        })
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

export const verifyUser = (userData) => (dispatch) => {
    axios
        .post([API_URL] + '/auth/verifyData', userData)
        .then((response) => {
            if (response.data.success) {
                // Dispatch an action if needed, e.g., for successful verification
                dispatch({
                    type: 'USER_VERIFIED',
                    payload: response.data.user,
                });
                // Clear any existing errors
                dispatch({
                    type: CLEAR_ERRORS,
                });
            } else {
                // Dispatch errors
                dispatch({
                    type: 'VERIFICATION_FAILED',
                    payload: response.data.errors,
                });
            }
        })
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// export const loginUser = (userData) => async (dispatch) => {
//   try {
//     // Fetch CSRF token from server
//     const resToken = await axios.get(`${API_URL}/auth/csrf-token`);
//     // const csrfToken = resToken.data.csrfToken;
//     const csrfToken = JSON.parse(Cookies.get("csrfToken"));
//
//     // Set CSRF token in headers of login request
//     axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken.csrfToken;
//
//     const res = await axios.post(`${API_URL}/auth/login`, userData);
//     const { token, loc_token, id_token, level } = res.data;
//
//     // Set tokens and user info to local storage and cookies
//     Cookies.set("Biodit", token);
//     Cookies.set("loc", loc_token);
//     localStorage.setItem("loc", loc_token);
//     localStorage.setItem("session", id_token);
//     localStorage.setItem("level", level);
//
//     // Set token to Auth header
//     setAuthToken(token);
//
//     // Decode token to get user data
//     const decoded = jwt_decode(token);
//
//     // Set current user
//     dispatch(setCurrentUser(decoded));
//   } catch (err) {
//     dispatch({
//       type: GET_ERRORS,
//       payload: err.response.data,
//     });
//   }
// };

export const passwordUser = (userData, history) => async (dispatch) => {
    try {
        await axios.post(`${API_URL}/auth/password`, userData);
        history.push('/login');
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    }
};

export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};

export const setUserLoading = () => {
    return {
        type: USER_LOADING,
    };
};

export const logoutUser = () => (dispatch) => {
    // Remove tokens and user info from local storage and cookies
    Cookies.remove('Biodit');
    Cookies.remove('loc');
    localStorage.removeItem('loc');
    localStorage.removeItem('session');
    localStorage.removeItem('level');
    localStorage.removeItem('x-version');

    // Remove auth header for future requests
    setAuthToken(false);

    // Set current user to empty object {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
};
