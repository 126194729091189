import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Cookies from 'js-cookie';
import axios from 'axios';
import { API_URL } from '../../utils/setApiUrl.js';
import { Slider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import zxcvbn from 'zxcvbn';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
}));

export default function PasswordResetForm(props) {
    const classes = useStyles();

    const initialAdminState = {
        password: '',
        password2: '',
    };

    const [password, setPassword] = useState(initialAdminState);

    const handlePasswordChange = (event) => {
        const { name, value } = event.target;
        setPassword({
            ...password,
            [name]: value,
        });

        // Example additional logic for password field
        if (name === 'password') {
            setPasswordStrength(zxcvbn(value));
        }
    };

    const [submitted, setSubmitted] = useState(false);

    // * Password Reset Form API Start
    // ! For error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [allocateMessage, setAllocationMessage] = useState('');
    const [allocateErrorMessage, setAllocationErrorMessage] = useState('');

    const [adminId] = React.useState(
        JSON.parse(localStorage.getItem('session')).session || ''
    );

    function updateAdminPassword(event) {
        event.preventDefault();
        const client = axios.create({
            baseURL: API_URL,
        });

        client
            .post(`/auth/password`, {
                id: adminId,
                password: password.password,
                password2: password.password2,
            })
            .then((response) => {
                setAllocationMessage(
                    <Alert severity="success">
                        Успешно променихте вашата парола!
                    </Alert>
                );
                setTimeout(() => {
                    Cookies.remove('Biodit');
                    Cookies.remove('loc');
                    localStorage.removeItem('loc');
                    window.location.href = '/login';
                }, 1200);
                console.log(response);
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    setIserror(true);
                    setAllocationMessage(
                        <Alert severity="error">
                            Паролата трябва да е минимум 6 символа!
                            {error.response.password}
                        </Alert>
                    );
                }
                console.log(error.response);
            });
    }

    // * Password Reset Form API END

    // ! password strength START
    const [passwordStrength, setPasswordStrength] = useState(null);

    const getPasswordStrengthColor = (score) => {
        switch (score) {
            case 0:
                return '#f44336'; // red
            case 1:
                return '#ff9800'; // orange
            case 2:
                return '#4caf50'; // light green
            case 3:
                return '#388e3c'; // green
            default:
                return '#388e3c'; // red
        }
    };

    const marks = [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }];

    // ! password strength END

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                {allocateMessage}
                {allocateErrorMessage}
                <form
                    className={classes.form}
                    onSubmit={updateAdminPassword}
                    noValidate
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Нова Парола"
                        type="password"
                        id="password"
                        onChange={handlePasswordChange}
                        value={password.password}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password2"
                        label="Повторете паролата"
                        type="password"
                        id="password2"
                        onChange={handlePasswordChange}
                        value={password.password2}
                    />
                    {password.password !== password.password2 && (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                            Паролите не съвпадат
                        </div>
                    )}
                    <p
                        style={{
                            fontSize: '13px',
                            textAlign: 'justify',
                            color: 'black',
                            fontWeight: 'lighter',
                            marginTop: '8px',
                        }}
                    >
                        Паролата трябва да съдържа минимум 7 символа,
                        включително главна буква, малка буква, цифра и специален
                        символ.
                    </p>
                    <Grid item sm={12} style={{ marginLeft: '3%' }}>
                        <Slider
                            value={
                                passwordStrength ? passwordStrength.score : 0
                            }
                            max={4}
                            step={null}
                            marks={marks}
                            style={{
                                color: getPasswordStrengthColor(
                                    passwordStrength
                                        ? passwordStrength.score
                                        : 0
                                ),
                            }}
                            track={false}
                        />
                    </Grid>
                    {/* <TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						name='password2'
						label='Повтори Парола'
						type='password'
						id='password2'
						onChange={onChange2}
						value={state.password2}
						error={errors.password2}
						style={{marginTop: "5px"}}
						className={classnames("", {
							invalid:
								classes.errors.password2 || classes.errors.passwordincorrect2,
						})}
						autoComplete='current-password'
					/>
					<span className='red-text' style={{color: "red"}}>
						{errors.password2}
						{errors.passwordincorrect2}
					</span> */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{
                            marginTop: '5px',
                        }}
                        disabled={
                            passwordStrength
                                ? passwordStrength.score < 2 ||
                                  password.password !== password.password2
                                : true
                        }
                        className={classes.submit}
                        onSubmit={updateAdminPassword}
                    >
                        Промени
                    </Button>
                </form>
            </div>
        </Container>
    );
}
