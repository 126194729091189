import React, { useEffect, useState } from 'react';
import {
    IconButton,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Select,
    MenuItem,
    FormControl,
} from '@mui/material';
import {
    Cancel as CancelIcon,
    Check as CheckIcon,
    Edit as EditIcon,
} from '@mui/icons-material';
import moment from 'moment-timezone';
import WebAppConfigService from '../../../services/WebAppConfigService';
import Alert from '@material-ui/lab/Alert';
import { styled } from '@mui/material/styles';

// Custom styled Select component
const StyledSelect = styled(Select)({
    '& .MuiSelect-select': {
        minWidth: '200px', // Set a fixed width for the dropdown
        maxWidth: '300px', // Maximum width
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#003d4d !important', // Active border color
    },
    '& .MuiSelect-icon': {
        color: '#003d4d', // Dropdown arrow color when active
    },
});

// Custom styled MenuItem
const StyledMenuItem = styled(MenuItem)({
    '&.Mui-selected': {
        backgroundColor: '#003d4d !important',
        color: 'white',
    },
    '&.Mui-selected:hover': {
        backgroundColor: '#003d4d !important',
        opacity: 0.9,
    },
    '&:hover': {
        backgroundColor: 'rgba(0, 61, 77, 0.1) !important',
    },
});

const SystemSettings = () => {
    useEffect(() => {
        retrieveSystemSettings();
    }, []);

    const [settings, setSettings] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [currentValue, setCurrentValue] = useState('');
    const [currentField, setCurrentField] = useState(null);
    const [timezones] = useState(moment.tz.names());

    const retrieveSystemSettings = () => {
        WebAppConfigService.getAll()
            .then(response => {
                setSettings(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const handleEditClick = (field, value) => {
        setEditMode(true);
        setCurrentValue(value);
        setCurrentField(field);
    };

    const handleCancelClick = () => {
        setEditMode(false);
        setCurrentValue('');
        setCurrentField(null);
    };

    const handleSaveClick = () => {
        const updateData = {
            [currentField]: currentValue,
        };

        WebAppConfigService.update(settings[0].id, updateData)
            .then(() => {
                retrieveSystemSettings();
                setEditMode(false);
                setCurrentValue('');
                setCurrentField(null);
                handleShowSnackbar(
                    `Успешно редактирахте стойността!`,
                    'success'
                );
            })
            .catch(e => {
                console.log(e);
            });
    };

    // ! Snackbars Start
    const [snackbars, setSnackbars] = useState([]);

    const handleCloseSnackbar = id => {
        setSnackbars(prevSnackbars =>
            prevSnackbars.filter(snackbar => snackbar.id !== id)
        );
    };

    const handleShowSnackbar = (message, severity) => {
        const id = new Date().getTime(); // Unique ID for each snackbar
        const newSnackbar = {
            id,
            message,
            severity,
            position: snackbars.length * 55,
        };

        setSnackbars(prevSnackbars => [...prevSnackbars, newSnackbar]);
    };
    // ! Snackbars End

    return (
        <TableContainer component={Paper}>
            {snackbars
                .slice()
                .reverse()
                .map((snackbar, index) => (
                    <Snackbar
                        key={snackbar.id}
                        open={true}
                        autoHideDuration={10000}
                        onClose={() => handleCloseSnackbar(snackbar.id)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        style={{ bottom: `${10 + index * 55}px` }}
                    >
                        <Alert
                            elevation={6}
                            variant="filled"
                            severity={snackbar.severity}
                            onClose={() => handleCloseSnackbar(snackbar.id)}
                        >
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                ))}
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Настройка на:</TableCell>
                        <TableCell align="center">Стойност</TableCell>
                        <TableCell align="center">Действия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {settings.length > 0 && (
                        <>
                            <TableRow>
                                <TableCell>
                                    Часова зона на устройство/а
                                </TableCell>
                                <TableCell align="center">
                                    {editMode && currentField === 'timezone' ? (
                                        <FormControl>
                                            <StyledSelect
                                                value={currentValue}
                                                onChange={e =>
                                                    setCurrentValue(
                                                        e.target.value
                                                    )
                                                }
                                                size="small" // Makes the dropdown smaller
                                            >
                                                {timezones.map(zone => (
                                                    <StyledMenuItem
                                                        key={zone}
                                                        value={zone}
                                                    >
                                                        {zone}
                                                    </StyledMenuItem>
                                                ))}
                                            </StyledSelect>
                                        </FormControl>
                                    ) : (
                                        settings[0].timezone
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {editMode && currentField === 'timezone' ? (
                                        <>
                                            <IconButton
                                                onClick={handleSaveClick}
                                            >
                                                <CheckIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={handleCancelClick}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <IconButton
                                            onClick={() =>
                                                handleEditClick(
                                                    'timezone',
                                                    settings[0].timezone
                                                )
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Обедна почивка (в минути)</TableCell>
                                <TableCell align="center">
                                    {editMode &&
                                    currentField === 'rest_minutes' ? (
                                        <TextField
                                            value={currentValue}
                                            onChange={e =>
                                                setCurrentValue(e.target.value)
                                            }
                                            type="number"
                                        />
                                    ) : (
                                        settings[0].rest_minutes
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {editMode &&
                                    currentField === 'rest_minutes' ? (
                                        <>
                                            <IconButton
                                                onClick={handleSaveClick}
                                            >
                                                <CheckIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={handleCancelClick}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <IconButton
                                            onClick={() =>
                                                handleEditClick(
                                                    'rest_minutes',
                                                    settings[0].rest_minutes
                                                )
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                            </TableRow>
                        </>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SystemSettings;
