import React, { useEffect, useState } from 'react';
import useCheckPermission from '../../../utils/permissions/useCheckPermissions';
import DepartmentDataService from '../../../services/DepartmentService';
import LocationDataService from '../../../services/LocationService';
import AdminDataService from '../../../services/Admins';
import AdminLocationsDataService from '../../../services/AdminLocations';
import AdminLocations from '../../../services/AdminLocations';
import { Snackbar } from '@mui/material';
import Alert from '@material-ui/lab/Alert';
// import EditModalForm from './EditFiles/EditModal'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { InputAdornment, Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AdministratorsRolesService from '../../../services/AdministratorsRolesService';
import zxcvbn from 'zxcvbn';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import axios from 'axios';
import { API_URL } from '../../../utils/setApiUrl.js';
import Chip from '@material-ui/core/Chip';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        '& .MuiTextField-root': {
            margin: theme.spacing(0.5),
            marginTop: 0,
            width: '98%',
        },
        '& .MuiButton-root': {
            margin: theme.spacing(1),
        },
        '& .MuiAlert-root': {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(1),
            },
        },
    }),
    passwordList: {
        '& .MuiListSubheader-gutters': {
            paddingLeft: '0px !important',
        },
    },
}));
const SystemUsers = () => {
    const isSuper = useCheckPermission('super');
    const level = localStorage.getItem('level');

    const classes = useStyles();

    const [adminRoles, setAdminRoles] = useState([]);
    const [users, setUser] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [usersPin, setUserPin] = useState([]);

    useEffect(() => {
        retrieveSystemUsers();
        // retrieveDepartments();
        retrieveLocations();
        retrieveAdminRoles();
    }, []);

    const client = axios.create({
        baseURL: API_URL,
    });

    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    const retrieveSystemUsers = () => {
        AdminDataService.getAll()
            .then((response) => {
                setUser(response.data);
            })
            .catch((e) => {
                setErrorMessages(['Cannot load user data']);
                setIserror(true);
            });
    };

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // * Retrieve Locations & Departments
    const [departmentsOptions, setDepartmentsOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);

    const retrieveLocations = () => {
        LocationDataService.getAll()
            .then((response) => {
                setLocationOptions(response.data);
            })
            .catch((e) => {
                setErrorMessages(['Cannot load user data']);
                setIserror(true);
            });
    };

    const retrieveAdminRoles = () => {
        AdministratorsRolesService.getAll()
            .then((response) => {
                setAdminRoles(response.data);
            })
            .catch((e) => {
                setErrorMessages(['Cannot load user data']);
                setIserror(true);
            });
    };

    const retrieveDepartments = () => {
        if (JSON.parse(localStorage.getItem('loc')).id >= 0) {
            DepartmentDataService.getAll(loc)
                .then((response) => {
                    return response.data.reduce((acc, item) => {
                        acc[item.name] = item.name;
                        setDepartmentsOptions(acc);
                        return acc;
                    }, {});
                })
                .catch((e) => {
                    setErrorMessages(['Cannot load user data']);
                    setIserror(true);
                });
        } else if (JSON.parse(localStorage.getItem('loc')).id === 'all') {
            DepartmentDataService.adminAll()
                .then((response) => {
                    return response.data.reduce((acc, item) => {
                        acc[item.name] = item.name;
                        setDepartmentsOptions(acc);
                        return acc;
                    }, {});
                })
                .catch((e) => {
                    setErrorMessages(['Cannot load user data']);
                    setIserror(true);
                });
        }
    };
    // * End of retrieving Locations & Departments

    // * Create new System User START
    const initialUserState = {
        id: '',
        name: '',
        nikname: '',
        active: true,
        email: '',
        role: '',
        phone: '',
        password: '',
        location: [],
    };
    const [newSystemUser, setNewSystemUser] = useState(initialUserState);

    // ! password strength START
    const [passwordStrength, setPasswordStrength] = useState(null);

    const getPasswordStrengthColor = (score) => {
        switch (score) {
            case 0:
                return '#f44336'; // red
            case 1:
                return '#ff9800'; // orange
            case 2:
                return '#4caf50'; // light green
            case 3:
                return '#388e3c'; // green
            default:
                return '#388e3c'; // red
        }
    };

    const marks = [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }];

    // ! password strength END

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // Update to handle phone input specifically
        if (name === 'phone') {
            // Remove spaces from the phone input
            const formattedValue = value.replace(/\s+/g, '');
            setNewSystemUser({ ...newSystemUser, [name]: formattedValue });
        } else {
            // Handle other inputs normally
            setNewSystemUser({ ...newSystemUser, [name]: value });
        }

        // Example additional logic for password field
        if (name === 'password') {
            setPasswordStrength(zxcvbn(value));
        }
    };

    // ! Snackbars Start
    const [snackbars, setSnackbars] = useState([]);

    const handleCloseSnackbar = (id) => {
        setSnackbars((prevSnackbars) =>
            prevSnackbars.filter((snackbar) => snackbar.id !== id)
        );
    };

    const handleShowSnackbar = (message, severity) => {
        const id = new Date().getTime(); // Unique ID for each snackbar
        const newSnackbar = {
            id,
            message,
            severity,
            position: snackbars.length * 55,
        };

        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };
    // ! Snackbars End

    const saveSystemUser = () => {
        handleShowSnackbar(`Създаваме акаунта... Моля, изчакайте!`, 'info');

        var userData = {
            id: newSystemUser.id,
            name: newSystemUser.name,
            nikname: newSystemUser.nikname,
            active: newSystemUser.active,
            email: newSystemUser.email,
            role: newSystemUser.role,
            phone: `+359${newSystemUser.phone}`,
            password: newSystemUser.password,
            location: [], // This seems to be not used since locations are handled separately
        };

        AdminDataService.create(userData)
            .then(async (response) => {
                const userId = response.data.id; // The newly created user's ID

                // Assuming newSystemUser.location is an array of location objects
                // Prepare the location data for each selected location
                const locationPromises = newSystemUser.location.map(
                    (location) => {
                        var locationData = {
                            admin_id: userId,
                            location_id: location.id, // Assuming each location object has an id property
                        };
                        return AdminLocationsDataService.create(locationData);
                    }
                );

                // Execute all location association requests in parallel
                try {
                    await Promise.all(locationPromises);
                    handleShowSnackbar(
                        `Локацията на администратора беше зададена успешно!`,
                        'success'
                    );
                } catch (error) {
                    handleShowSnackbar(
                        `Не можахме да зададем локацията на администратора. Моля опитайте отново!`,
                        'error'
                    );
                    console.error(error);
                }

                // Additional logic for after successful user and locations creation
                retrieveSystemUsers();
                setNewSystemUser(initialUserState);
                setPasswordStrength(null);
                handleShowSnackbar(`Акаунта беше създаден успешно!`, 'success');
            })
            .catch((e) => {
                handleShowSnackbar(
                    `Възникна грешка. Моля опитайте отново!`,
                    'error'
                );
                console.error(e);
            });
    };
    // * Create new System User END

    // * Update System User Start
    async function updateSystemUser(e, link) {
        try {
            if (link.active === true) {
                const response = await AdminDataService.updateRole(
                    e.target.id,
                    {
                        active: false,
                    }
                );
                console.log(response);
                retrieveSystemUsers(); // Rebuild System Users from the retrieveSystemUsers function
            } else {
                const response = await AdminDataService.updateRole(
                    e.target.id,
                    {
                        active: true,
                    }
                );
                console.log(response);
                retrieveSystemUsers(); // Rebuild System Users from the retrieveSystemUsers function
            }
        } catch (error) {
            console.log(error);
        }
    }

    // * Update System User End

    // * Edit System User Start
    const [edit, setEdit] = useState(false);
    const [editId, setEditId] = useState(null); // provide a default value

    async function getSystemUser(link) {
        setEdit(true);

        try {
            const response = await AdminDataService.get(link.id);
            const userData = response.data;

            // Map user's locations to the format expected by the Autocomplete component
            console.log('User Data:', userData); // Debugging

            const userLocations = userData.AdminLocation.map((loc) => {
                return locationOptions.find(
                    (option) => option.id === loc.location_id
                );
            }).filter((loc) => loc); // Remove any undefined or null

            console.log('Mapped Locations:', userLocations); // Debugging

            setNewSystemUser({
                id: userData.id,
                name: userData.name,
                nikname: userData.nikname,
                active: userData.active,
                email: userData.email,
                role: userData.AdminRole.id, // Use the role ID
                phone: userData.phone.startsWith('+359')
                    ? userData.phone.substring(4)
                    : userData.phone,
                password: '', // Assuming password is not retrieved for security reasons
                location: userLocations, // Array of matched location objects
            });
            setEditId(userData.id);
        } catch (error) {
            console.log(error);
        }
    }

    async function editSystemUser(e, link) {
        try {
            // Retrieve current user data, including current locations
            const currentUserResponse = await AdminDataService.get(editId);
            const currentUserData = currentUserResponse.data;
            const currentLocationIds = new Set(
                currentUserData.AdminLocation.map((loc) => loc.location_id)
            );

            const response = await AdminDataService.update(editId, {
                id: newSystemUser.id,
                name: newSystemUser.name,
                nikname: newSystemUser.nikname,
                active: newSystemUser.active,
                email: newSystemUser.email,
                role: newSystemUser.role,
                phone: `+359${newSystemUser.phone}`,
                password: newSystemUser.password,
                location: newSystemUser.location.map((loc) => loc.id),
            });

            if (response.status === 200) {
                // Determine the updated locations
                const updatedLocationIds = new Set(
                    newSystemUser.location.map((loc) => loc.id)
                );

                // Delete locations that were removed
                const locationsToDelete = [...currentLocationIds].filter(
                    (id) => !updatedLocationIds.has(id)
                );
                for (const locId of locationsToDelete) {
                    await client.delete(
                        `/v1/adminLocations/${editId}/${locId}`
                    );
                }

                // Create new locations
                for (const location of newSystemUser.location) {
                    if (!currentLocationIds.has(location.id)) {
                        await AdminLocations.create({
                            admin_id: editId,
                            location_id: location.id,
                        });
                    }
                }

                handleShowSnackbar(
                    `Успешно редактирахте администратора!`,
                    'success'
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    // * Edit System User End

    // * Remove System User Start
    async function removeSystemUser(e, link) {
        try {
            const response = await AdminDataService.remove(e.currentTarget.id);
            console.log(response);
            retrieveSystemUsers();
        } catch (error) {
            console.log(error);
        }
    }

    // * Remove System User End

    // * Admin Locations Logic
    // Assuming you have a state for the selected options
    const [selectedOptions, setSelectedOptions] = React.useState([]);

    // Custom delete action
    const handleDelete = (optionToDelete) => async () => {
        try {
            const response = await AdminLocationsDataService.remove(
                editId,
                optionToDelete.id
            );
            if (response.status === 200) {
                handleShowSnackbar(
                    `Успешно премахнахте локацията` +
                        ' ' +
                        optionToDelete.id +
                        ` от потребител - ${newSystemUser.name}`,
                    'success'
                );
                setNewSystemUser((prevState) => ({
                    ...prevState,
                    location: prevState.location.filter(
                        (loc) => loc.id !== optionToDelete.id
                    ),
                }));
            }
        } catch (e) {
            console.error('Error removing location:', e);
            setErrorMessages(['Cannot load user data']); // Assuming setErrorMessages and setIserror are defined elsewhere
            setIserror(true);
        }
    };

    const handleCreate = async (selectedOption) => {
        try {
            const response = await AdminLocationsDataService.create({
                admin_id: editId,
                location_id: selectedOption.number,
            });
            if (response.status === 200) {
                handleShowSnackbar(
                    `Успешно добавихте локацията` +
                        ' ' +
                        selectedOption.number +
                        ` към потребител - ${newSystemUser.name}`,
                    'success'
                );
                setNewSystemUser((prevState) => {
                    // Check if the selectedOption is already included to prevent duplicates
                    const isAlreadyIncluded = prevState.location.some(
                        (location) => location.id === selectedOption.id
                    );
                    if (!isAlreadyIncluded) {
                        return {
                            ...prevState,
                            location: [...prevState.location, selectedOption],
                        };
                    } else {
                        return prevState; // Return the existing state if the option is already included
                    }
                });
            }
        } catch (e) {
            console.error('Error adding location:', e);
            setErrorMessages(['Cannot load user data']);
            setIserror(true);
        }
    };

    // * Different Clients
    const currentHost = window.location.hostname;
    const isBilla = currentHost === 'billa.biodit.bg';

    // Add this function after the other state declarations
    const generatePassword = () => {
        const length = 8;
        const charset = {
            uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            lowercase: 'abcdefghijklmnopqrstuvwxyz',
            numbers: '0123456789',
            symbols: '!@#$%^&*()_+-=[]{}|;:,.<>?'
        };

        // Ensure at least one character from each set
        let password = '';
        password += charset.uppercase.charAt(Math.floor(Math.random() * charset.uppercase.length));
        password += charset.lowercase.charAt(Math.floor(Math.random() * charset.lowercase.length));
        password += charset.numbers.charAt(Math.floor(Math.random() * charset.numbers.length));
        password += charset.symbols.charAt(Math.floor(Math.random() * charset.symbols.length));

        // Fill the rest randomly
        const allChars = Object.values(charset).join('');
        for (let i = password.length; i < length; i++) {
            password += allChars.charAt(Math.floor(Math.random() * allChars.length));
        }

        // Shuffle the password
        password = password.split('').sort(() => Math.random() - 0.5).join('');

        // Update the state and trigger password strength check
        setNewSystemUser(prev => ({ ...prev, password }));
        setPasswordStrength(zxcvbn(password));
    };

    // Add this state near your other state declarations
    const [showPassword, setShowPassword] = useState(false);

    // Add this handler function near your other handlers
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            {snackbars
                .slice()
                .reverse()
                .map((snackbar, index) => (
                    <Snackbar
                        key={snackbar.id}
                        open={true}
                        autoHideDuration={10000}
                        onClose={() => handleCloseSnackbar(snackbar.id)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        style={{ bottom: `${10 + index * 55}px` }}
                    >
                        <Alert
                            elevation={6}
                            variant="filled"
                            severity={snackbar.severity}
                            onClose={() => handleCloseSnackbar(snackbar.id)}
                        >
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                ))}
            <main className={classes.content}>
                <Grid container spacing={0} justify="center">
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        style={{ height: '74vh' }}
                        alignItems="center"
                    >
                        <Paper
                            elevation={0}
                            square
                            style={{
                                borderRight: '1px solid #ccc',
                                height: '100%',
                                padding: '2%',
                                paddingTop: '0',
                                overflowY: 'scroll',
                            }}
                        >
                            <List
                                className={classes.root}
                                lg={{
                                    width: '100%',
                                    maxWidth: 360,
                                    bgcolor: 'background.paper',
                                    position: 'relative',
                                    overflow: 'scroll',
                                    maxHeight: '200px',
                                    '& ul': { padding: 0 },
                                }}
                                subheader={
                                    <ListSubheader>Потребители</ListSubheader>
                                }
                            >
                                {users.map((link) => {
                                    return (
                                        <div>
                                            <ListItem
                                                key={link.id}
                                                id={link.id}
                                            >
                                                <ListItemText
                                                    primary={link.nikname}
                                                    secondary={
                                                        link.AdminRole.role_name
                                                    }
                                                />
                                                <ListItemSecondaryAction style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                    <Switch
                                                        id={link.id}
                                                        name={'Включено'}
                                                        checked={Boolean(
                                                            link.active
                                                        )}
                                                        value={link.active}
                                                        onClick={(e) =>
                                                            updateSystemUser(
                                                                e,
                                                                link
                                                            )
                                                        }
                                                    />
                                                    {(() => {
                                                        if (
                                                            isSuper ||
                                                            level === 'admin'
                                                        ) {
                                                            return (
                                                                <>
                                                                    <div style={{ width: '1px', height: '24px', backgroundColor: '#ccc', margin: '0 4px' }} />
                                                                    <Tooltip title="Редактирай потребител" placement="top">
                                                                        <Button
                                                                            id={link.id}
                                                                            className={
                                                                                classes.adminButton
                                                                            }
                                                                            aria-controls="menu-admin"
                                                                            style={{
                                                                                minWidth: '40px',
                                                                                padding: 0,
                                                                                margin: 0
                                                                            }}
                                                                            onClick={() => {
                                                                                setEditId(
                                                                                    link.id
                                                                                );
                                                                                getSystemUser(
                                                                                    link
                                                                                );
                                                                            }}
                                                                        >
                                                                            <CreateIcon />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </>
                                                            );
                                                        } else {
                                                            return '';
                                                        }
                                                    })()}

                                                    {isSuper && (
                                                        <>
                                                            <div style={{ width: '1px', height: '24px', backgroundColor: '#ccc', margin: '0 4px' }} />
                                                            <Tooltip title="Изтрий потребител" placement="top">
                                                                <Button
                                                                    id={link.id}
                                                                    className={
                                                                        classes.adminButton
                                                                    }
                                                                    aria-controls="menu-admin"
                                                                    style={{
                                                                        minWidth: '40px',
                                                                        padding: 0,
                                                                        margin: 0
                                                                    }}
                                                                    onClick={(e) =>
                                                                        removeSystemUser(
                                                                            e,
                                                                            link
                                                                        )
                                                                    }
                                                                >
                                                                    <DeleteIcon />
                                                                </Button>
                                                            </Tooltip>
                                                        </>
                                                    )}
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </div>
                                    );
                                })}
                            </List>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <Paper
                            square
                            elevation={0}
                            style={{
                                padding: '2%',
                                paddingTop: '0',
                            }}
                        >
                            <List
                                subheader={
                                    <ListSubheader>
                                        Добавяне на потребител
                                    </ListSubheader>
                                }
                                className={classes.root}
                            >
                                <form className={classes.root}>
                                    <TextField
                                        required
                                        id="outlined-required name"
                                        label="Име"
                                        name="name"
                                        style={{ marginTop: 0 }}
                                        value={newSystemUser.name || ''}
                                        onChange={handleInputChange}
                                        size="small"
                                        variant="standard"
                                    />
                                    <TextField
                                        required
                                        id="outlined-required nikname"
                                        label="Потребителско име"
                                        name="nikname"
                                        value={newSystemUser.nikname || ''}
                                        onChange={handleInputChange}
                                        size="small"
                                        variant="standard"
                                    />
                                    <TextField
                                        id="outlined-required email"
                                        label="Имейл"
                                        name="email"
                                        value={newSystemUser.email || ''}
                                        onChange={handleInputChange}
                                        size="small"
                                        variant="standard"
                                    />
                                    <TextField
                                        {...(isBilla ? { required: true } : {})}
                                        id="outlined-required phone"
                                        label="Телефон"
                                        name="phone"
                                        value={newSystemUser.phone || ''}
                                        onChange={handleInputChange}
                                        size="small"
                                        variant="standard"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    +359
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        id="role"
                                        select
                                        label="Вид права"
                                        name="role"
                                        required
                                        value={newSystemUser.role || ''}
                                        onChange={handleInputChange}
                                        size="small"
                                        variant="standard"
                                    >
                                        {adminRoles.map((adminRole) => (
                                            <MenuItem
                                                key={adminRole.id}
                                                value={adminRole.id}
                                            >
                                                {adminRole.role_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {(() => {
                                        if (edit) {
                                            return (
                                                <Autocomplete
                                                    multiple
                                                    value={
                                                        newSystemUser.location ||
                                                        []
                                                    }
                                                    onChange={(
                                                        event,
                                                        newValue,
                                                        reason
                                                    ) => {
                                                        // Handle the selection differently based on the reason
                                                        if (
                                                            reason ===
                                                            'select-option'
                                                        ) {
                                                            const selectedOption =
                                                                newValue[
                                                                    newValue.length -
                                                                        1
                                                                ];
                                                            // Await handleCreate or ensure it updates the state correctly
                                                            handleCreate(
                                                                selectedOption,
                                                                () => {
                                                                    // Callback to update state, if necessary
                                                                }
                                                            );
                                                        } else if (
                                                            reason ===
                                                            'remove-option'
                                                        ) {
                                                            // Handle option removal here
                                                            // Note: You'll need the specific option that was removed if you want to use handleDelete
                                                        }

                                                        // Update the newSystemUser state with the new location array regardless of the action
                                                        setNewSystemUser(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                location:
                                                                    newValue,
                                                            })
                                                        );
                                                    }}
                                                    id="location"
                                                    getOptionLabel={(
                                                        location
                                                    ) =>
                                                        location.name.toString()
                                                    }
                                                    getOptionSelected={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    options={locationOptions}
                                                    size="small"
                                                    renderTags={(
                                                        value,
                                                        getTagProps
                                                    ) =>
                                                        value.map(
                                                            (option, index) => (
                                                                // Customize Chip here
                                                                <Chip
                                                                    label={
                                                                        option.name
                                                                    } // Adjust based on your data structure
                                                                    // We're not spreading getTagProps directly
                                                                    {...getTagProps(
                                                                        {
                                                                            index,
                                                                        }
                                                                    ).onDelete}
                                                                    // Use custom handleDelete function
                                                                    onDelete={handleDelete(
                                                                        option
                                                                    )}
                                                                    // Customize the delete icon by providing a custom component if needed
                                                                    deleteIcon={
                                                                        <DeleteIcon />
                                                                    } // Replace with your custom icon
                                                                />
                                                            )
                                                        )
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Локация"
                                                            required
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            );
                                        }
                                        return (
                                            <Autocomplete
                                                multiple
                                                value={
                                                    newSystemUser.location || []
                                                }
                                                onChange={(
                                                    event,
                                                    newValue,
                                                    reason
                                                ) => {
                                                    // Update the newSystemUser state with the new location array regardless of the action
                                                    setNewSystemUser(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            location: newValue,
                                                        })
                                                    );
                                                }}
                                                id="location"
                                                getOptionLabel={(location) =>
                                                    location.name.toString()
                                                }
                                                getOptionSelected={(
                                                    option,
                                                    value
                                                ) => option.id === value.id}
                                                options={locationOptions}
                                                size="small"
                                                renderTags={(
                                                    value,
                                                    getTagProps
                                                ) =>
                                                    value.map(
                                                        (option, index) => (
                                                            // Customize Chip here
                                                            <Chip
                                                                label={
                                                                    option.name
                                                                } // Adjust based on your data structure
                                                                // We're not spreading getTagProps directly
                                                                {...getTagProps(
                                                                    { index }
                                                                )}
                                                            />
                                                        )
                                                    )
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Локация"
                                                        required
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        );
                                    })()}

                                    <TextField
                                        required
                                        id="outlined-required password"
                                        label="Парола"
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={newSystemUser.password || ''}
                                        onChange={handleInputChange}
                                        size="small"
                                        variant="standard"
                                        helperText="Паролата трябва да съдържа минимум 7 символа, включително главна буква, малка буква, цифра и специален символ."
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip title="Генерирай нова парола" placement="top">
                                                        <IconButton
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                generatePassword();
                                                            }}
                                                            size="small"
                                                            edge="end"
                                                        >
                                                            <AutorenewIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Покажи/Скрий паролата" placement="top">
                                                        <IconButton
                                                            onClick={handleClickShowPassword}
                                                            size="small"
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Grid
                                        item
                                        sm={5}
                                        style={{ marginLeft: '3%' }}
                                    >
                                        <Slider
                                            value={
                                                passwordStrength
                                                    ? passwordStrength.score
                                                    : 0
                                            }
                                            max={4}
                                            step={null}
                                            marks={marks}
                                            style={{
                                                color: getPasswordStrengthColor(
                                                    passwordStrength
                                                        ? passwordStrength.score
                                                        : 0
                                                ),
                                            }}
                                            track={false}
                                        />
                                    </Grid>
                                    {(() => {
                                        if (edit) {
                                            return (
                                                <Button
                                                    onClick={editSystemUser}
                                                    variant="contained"
                                                    color="primary"
                                                    // disabled={
                                                    //     passwordStrength
                                                    //         ? passwordStrength.score <
                                                    //           2
                                                    //         : true
                                                    // }
                                                >
                                                    Редактирай
                                                </Button>
                                            );
                                        }
                                        return (
                                            <Button
                                                onClick={saveSystemUser}
                                                variant="contained"
                                                color="primary"
                                                disabled={
                                                    passwordStrength
                                                        ? passwordStrength.score <
                                                          2
                                                        : true
                                                }
                                            >
                                                Добави
                                            </Button>
                                        );
                                    })()}
                                </form>
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </main>
        </div>
    );
};

export default SystemUsers;
